import auth0 from 'auth0-js'
import { notification } from 'antd'

class AuthService {
  constructor() {
    this.auth0 = new auth0.Authentication({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE_API,
      responseType: 'token',
      redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URL,
    })

    this.getProfile = this.getProfile.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.signIn = this.signIn.bind(this)
    this.signOut = this.signOut.bind(this)
    this.resetPassword = this.resetPassword.bind(this)
  }

  getProfile() {
    return this.profile
  }

  getIdToken() {
    return this.idToken
  }

  isAuthenticated() {
    return new Date().getTime() < this.expiresAt
  }

  signIn(email, password) {
    let response
    const that = this
    return new Promise(function(resolve, reject) {
      console.log(reject)
      that.auth0.login(
        {
          realm: 'Username-Password-Authentication',
          email,
          username: email,
          password,
          scope: 'openid profile email',
        },
        function(err, authResult) {
          if (err) {
            notification.error({
              message: 'Invalid Username/Password',
            })
            response = false
            resolve(response)
          }
          const respose = { body: authResult }
          localStorage.setItem(
            `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::default::openid profile email`,
            JSON.stringify(respose),
          )
          localStorage.setItem('app.settings.isMobileView', false)
          response = true
          resolve(response)
        },
      )
    })
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) {
          reject(err)
        }

        if (!authResult || !authResult.idToken) {
          reject(err)
        }
        this.idToken = authResult.idToken
        this.profile = authResult.idTokenPayload
        this.expiresAt = authResult.idTokenPayload.exp * 1000
        resolve()
      })
    })
  }

  signOut() {
    // clear id token, profile, and expiration
    this.idToken = null
    this.profile = null
    this.expiresAt = null
    /* this.auth0.logout({
      returnTo: 'http://localhost:3000',
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    }); */
    localStorage.setItem(
      `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::default::openid profile email`,
      '',
    )
  }

  resetPassword(emailVal) {
    this.auth0.changePassword({
      connection: 'Username-Password-Authentication',
      email: emailVal,
    })
  }
}

const auth0Client = new AuthService()

export default auth0Client
