const urls = {
  SIGNUP_URL: '/api/auth/sign-up',
  SIGN_IN: '/api/auth/sign-in',
  UPLOAD_RESUME: '/resume',
  UPLOAD_PROFILE: '/profile',
  UPDATE_USER: '/user',
  RESET_PASSWORD: '/api/auth/reset-password',
}

export default urls
