import axios from 'axios'
import store from 'store'
import { notification } from 'antd'

// const apiClient = axios.create({
// baseURL: '/api',
// timeout: 1000,
// headers: { 'X-Custom-Header': 'foobar' }
// })

const axiosInstance = axios.create({
  // baseURL: `http://localhost:8080`,
  baseURL: `http://wabackend-env.eba-vscvsdgq.us-west-2.elasticbeanstalk.com`,
})

axiosInstance.interceptors.request.use(function(config) {
  const findToken = localStorage.getItem(
    `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::default::openid profile email`,
  )

  let auth0spajs
  try {
    auth0spajs = JSON.parse(findToken)
  } catch (e) {
    console.log(e)
  }

  if (!auth0spajs || Object.keys(auth0spajs).length === 0) {
    return config
  }
  const token = auth0spajs.body.id_token ? auth0spajs.body.id_token : auth0spajs.body.idToken
  const accessToken = auth0spajs.body.access_token
    ? auth0spajs.body.access_token
    : auth0spajs.body.accessToken
  config.headers.idToken = token
  config.headers.accessToken = accessToken
  return config
})

axiosInstance.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.AccessToken = accessToken
  }
  return request
})

axiosInstance.interceptors.response.use(undefined, error => {
  const { response } = error
  if (response) {
    const { data } = response
    if (data) {
      notification.error({
        message: data.status,
        description: data.message,
      })
    }
  }
})

export default axiosInstance
