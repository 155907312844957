import urls from 'constants/urls'
import { notification } from 'antd'
import axiosInstance from '../index'
import auth0Client from '../../AuthService'

/* export function login(email, password) {
  return auth0Client.signIn(email, password)
}
*/

export function resetPassword(email) {
  const request = { email }

  return axiosInstance
    .post(urls.RESET_PASSWORD, request)
    .then(response => {
      console.log(response)
      notification.success({
        message: 'Successfully sent an email to your Id.',
      })
      return true
    })
    .catch(error => {
      console.log(error)
      notification.error({
        description: error.errorCode,
        message: 'Failed to send reset email',
      })
      return false
    })
}

export function login(email, password) {
  const request = { email, password }
  return axiosInstance
    .post(urls.SIGN_IN, request)
    .then(response => {
      const finalResponse = { body: response.data }
      localStorage.setItem(
        `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::default::openid profile email`,
        JSON.stringify(finalResponse),
      )
      localStorage.setItem('app.settings.isMobileView', false)
      return true
    })
    .catch(error => {
      console.log(error)
      notification.error({
        description: error.errorCode,
        message: 'Invalid Username/Password',
      })
      return false
    })
}

export async function register(email, password, name) {
  const request = { firstName: name, lastName: name, email, password, phoneNumber: '4803477273' }

  return axiosInstance.post(urls.SIGNUP_URL, request).then(
    function(response) {
      console.log(response)
      return true
    },
    error => {
      notification.warning({
        message: error.errorCode,
        description: error.message,
      })
    },
  )
}

export async function updateUser(payload) {
  const request = {
    name: payload.name,
    phoneNumber: payload.phoneNumber,
    password: payload.password,
  }
  if (request.length === 0) {
    return false
  }
  return axiosInstance.post(urls.UPDATE_USER, request).then(
    function(response) {
      console.log(response)
      return true
    },
    error => {
      notification.warning({
        message: error.errorCode,
        description: error.message,
      })
    },
  )
}

export async function currentAccount() {
  return axiosInstance
    .get('/me')
    .then(res => {
      return res && res.data ? res.data : undefined
    })
    .catch(error => {
      console.log(error)
    })
}

export async function logout() {
  auth0Client.signOut()
  return true
}
