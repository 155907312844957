import React from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
  const clientSecret = process.env.REACT_APP_AUTH0_SECRET
  const history = useHistory()

  const onRedirectCallback = appState => {
    window.location.hash = window.location.hash;
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        // : (window.location.href = 'http://localhost:3000/#/apps/profile'),
        : window.location.pathname
    )

    // history.push(appState?.returnTo || window.location.pathname);
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      clientSecret={clientSecret}
      grantType="client_credentials"
      cacheLocation="localstorage"
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      scope="openid profile email"
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
